import React, { useEffect } from 'react';
import {Helmet} from "react-helmet";
import 'bootstrap/dist/css/bootstrap.css';
import Footer from '../components/Footer/Footer';

const Layout = ({ children }) => {

  useEffect(()=> {
    const script = document.createElement('script');
    script.src = 'https://sibforms.com/forms/end-form/build/main.js'; 
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, [])

  return (
    <>
      <Helmet>

      {/* <!-- Matomo --> */}
      <script>
        {
          `
          var _paq = window._paq = window._paq || [];
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function () {
            var u = "https://matomo.publicisfrance.com/";
            _paq.push(['setTrackerUrl', u + 'matomo.php']);
            _paq.push(['setSiteId', '11']);
            var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
          })();
          `
        }
       
      </script>
      {/* <!-- End Matomo Code --> */}


      <script>
        {
          `
          window.REQUIRED_CODE_ERROR_MESSAGE = 'Please choose a country code:';
          window.LOCALE = 'en';
          window.EMAIL_INVALID_MESSAGE = window.SMS_INVALID_MESSAGE = "The information provided is invalid. Please review the field format and try again.";
          window.REQUIRED_ERROR_MESSAGE = "This field cannot be left blank. ";
          window.GENERIC_INVALID_MESSAGE = "The information provided is invalid. Please review the field format and try again.";
          window.translation = {
            common: {
              selectedList: '{quantity} list selected',
              selectedLists: '{quantity} lists selected'
            }
          };
          var AUTOHIDE = Boolean(0);
          `
        }
        
      </script>

        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css" integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A==" crossorigin="anonymous" referrerpolicy="no-referrer" />
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.2.0/css/all.css"/>
        <link rel="stylesheet" href="https://sibforms.com/forms/end-form/build/sib-styles.css" />
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8"
          data-domain-script="1492f0fa-348d-40dc-bd17-f487acec3aa0"></script>
        <script>
        {
          `
            function OptanonWrapper() { }
          `
        }
        </script>
      </Helmet>
      <div id="main">
        {children}
        <Footer />
      </div>
    </>
  );
};

export default Layout;
