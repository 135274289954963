import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../layout/Layout';
import SEO from '../components/Seo';
import Nav from '../components/Nav/Nav';
import VideoPlayer from '../components/Video/VideoPlayer/VideoPlayer';
import ImageCreation from '../components/Image/ImageCreation/ImageCreation';

const Index = ({ data }) => {
  
  const dataHome = data?.contentfulHomePage;

  useEffect(()=>{
    if (window.innerWidth <= 620) {
      document.location.href = "/creations";
    }
  }, [])

  console.log(dataHome);
  
  return (
    <Layout>
      <SEO
        lang="fr"
        title={dataHome?.titleOfThePage}
        description={dataHome?.descriptionOfThePage}
      />
      <div id="main-wrapper" className="row">
        <Nav />
        <section id="container" className="col-lg-9 col-md-12 mt-6">
          { dataHome?.featuredCreation?.videoThumbnail?.file?.url ?
              <VideoPlayer videoFileUrl={dataHome?.featuredCreation?.videoThumbnail?.file?.url} dataFullScreen={true} />
            :
              dataHome?.featuredCreation?.visuelThumbnail?.gatsbyImageData ?
                <ImageCreation img={dataHome.featuredCreation.visuelThumbnail.gatsbyImageData} alt={dataHome.featuredCreation.visuelThumbnail.title} />
              :
              <h2>PAS D'IMAGE</h2>
          }
          <div className="content-hp col-lg-12">
          <h2 className="title mt-4 mb-0">{dataHome?.featuredCreation?.creationDescription}</h2>
            <div className="discover">
              <a href={`/creations/${dataHome?.featuredCreation.slug}`} aria-current="page">Discover</a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulHomePage {
      titleOfThePage
      descriptionOfThePage
      featuredCreation {
        visuelThumbnail {
          title
          gatsbyImageData
        }
        videoThumbnail {
          file {
            url
          }
        }
        slug
        creationDescription
      }
    }
  }
`

export default Index;
